import {gql} from "apollo-angular";

export const GET_TEAMS = gql`
  query($filter: [TeamFilter!]!, $sort: [TeamSort!]!, $skip: Int, $take: Int) {
    teams(filter: $filter, skip: $skip, sort: $sort, take: $take) {
      totalCount
      count
      nodes {
        id
        name
        abbreviation
        leader {
          id
          callSign
        }
        members {
          id
          identityId
          callSign
          banned
          comment
          nonLiabilityWaverSigned
          neverPlayed
          team {
            id
          }
        }
      }
    }
  }
`;

export const GET_TEAMS_WITH_LEADER = gql`
  query($filter: [TeamFilter!]!, $sort: [TeamSort!]!, $skip: Int, $take: Int) {
    teams(filter: $filter, skip: $skip, sort: $sort, take: $take) {
      totalCount
      count
      nodes {
        id
        name
        abbreviation
        leader {
          id
          callSign
        }
        members {
          id
          identityId
          callSign
          banned
          comment
          nonLiabilityWaverSigned
          neverPlayed
          team {
            id
          }
        }
      }
    }

    players(take: 99999) {
      totalCount
      count
      nodes {
        id
        identityId
        callSign
        banned
        comment
        nonLiabilityWaverSigned
        neverPlayed
        team {
          id
        }
      }
    }
  }
`;

export const GET_ALL_TEAMS = gql`
  query {
    teams(take: 99999) {
      totalCount
      count
      nodes {
        id
        name
        abbreviation
      }
    }
  }
`;

export const ADD_TEAMS = gql`
  mutation($team: TeamCreateInput!) {
    team {
      create(team: $team) {
        id
        name
        abbreviation
      }
    }
  }
`;

export const EDIT_TEAMS = gql`
  mutation($id: Int!, $change: TeamEditInput!) {
    team {
      edit(id: $id, change: $change) {
        id
        name
        abbreviation
      }
    }
  }
`;

export const DELETE_TEAMS = gql`
  mutation($id: Int!) {
    team {
      delete(id: $id)
    }
  }
`;
