import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map } from 'rxjs';
import { Logger } from 'src/app/services/logger/logger.service';

const log = new Logger('AuthGuard')

@Injectable()
export class AuthGuard {

  constructor(
    public oidcSecurityService: OidcSecurityService
  ) { }

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(
      map((response) => {
        log.debug(response);
        return response.isAuthenticated;
      })
    );
  }
}
