<div class="table-caption">
  <div class="table-caption-table-info">
    <div class="table-caption-text">
      <ng-container *ngIf="!loading">{{ count }} {{ 'common.of' | translate }}
        {{ totalRecords }}
      </ng-container>
      {{ countHeader | translate }}
    </div>
  </div>

  <div class="table-caption-btn-wrapper btn-wrapper">
    <app-multi-select-hidden-columns *ngIf="columns && hiddenColumns" [table]="name" [columns]="columns"
                                     [(hiddenColumns)]="hiddenColumns"></app-multi-select-hidden-columns>
    <p-button *ngIf="resetSort" tooltipPosition="left" pTooltip="{{'common.reset_sort' | translate}}"
              icon="pi pi-sort-alt-slash"
              class="icon-btn btn" (click)="resetSort()">
    </p-button>
    <p-button *ngIf="resetFilters" tooltipPosition="left" pTooltip="{{'common.reset_filters' | translate}}"
              icon="pi pi-filter-slash"
              class="icon-btn btn" (click)="resetFilters()">
    </p-button>
  </div>
</div>
