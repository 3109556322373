import {Component, Input} from '@angular/core';
import {JouleRulesService} from "src/app/modules/shared/services/joule-rules.service";
import {GameDayPlayerWeapon} from "src/app/models/entities/chroni";

@Component({
  selector: 'app-joule-input',
  templateUrl: './joule-input.component.html',
  styleUrl: './joule-input.component.scss'
})
export class JouleInputComponent {

  @Input() name: string = "combination-lock-wheel";
  @Input({required: true}) isAdult!: boolean;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;

  private _weapon!: GameDayPlayerWeapon;
  @Input({required: true})
  get weapon(): GameDayPlayerWeapon {
    return this._weapon;
  }

  set weapon(value: GameDayPlayerWeapon) {
    this._weapon = value;
    this.value = value.energy;
    this.pickerColor = this.jouleRules.getWeaponColor(this.isAdult, value) ?? this.pickerColor;
  }

  protected valueInput: string = '000';

  set value(value: number | undefined) {
    if (!value) {
      value = 0.00;
    }
    let valueString = value.toString().replace(',', '').replace('.', '');
    while (valueString.length < 3) {
      valueString += '0';
    }
    this.valueInput = valueString;
    this.weapon.energy = this.getValue();
    this.setColor();
  }

  protected pickerColor: string = '#fff';

  constructor(
    private jouleRules: JouleRulesService
  ) {
  }

  getValue() {
    return +(this.valueInput.slice(0, 1) + "." + this.valueInput.slice(1));
  }

  setColor() {
    this.pickerColor = this.jouleRules.getWeaponColor(this.isAdult, this._weapon) ?? '#000';
  }

  onInput(index: number, event: KeyboardEvent) {
    let value = event.key;

    if (value === undefined || value === null || isNaN(Number(value)) || value.length !== 1) {
      value = '0';
    }

    if (this.valueInput === undefined) {
      this.valueInput = '';
    }

    let valueInput = this.valueInput;

    while (valueInput.length <= index) {
      valueInput += '0';
    }

    valueInput = valueInput.substring(0, index) + value + valueInput.substring(index + 1);
    this.valueInput = valueInput.slice(0, 3);
    this.value = this.getValue();

    switch (index) {
      case 0:
        document.getElementById(this.name + (index + 1))?.focus();
        break;
      case 1:
        document.getElementById(this.name + (index + 1))?.focus();
        break;
    }
  }
}
