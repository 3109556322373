import {gql} from "apollo-angular";

export const GET_GAME_DAYS = gql`
  query($filter: [GameDayFilter!]!, $sort: [GameDaySort!]!, $skip: Int, $take: Int) {
    gameDays(filter: $filter, skip: $skip, sort: $sort, take: $take) {
      totalCount
      count
      nodes {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const GET_ALL_GAME_DAYS = gql`
  query {
    gameDays(take: 99999) {
      nodes {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const GET_ACTIVE_GAME_DAY = gql`
  query {
    gameDays(filter: {active: {equal: true}}) {
      nodes {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const GET_ACTIVE_GAME_DAY_CHECKIN = gql`
  query($filter: [GameDayCheckInFilter!]!, $sort: [GameDayCheckInSort!]!, $skip: Int, $take: Int) {
    gameDays(filter: {active: {equal: true}}) {
      nodes {
        id
        date
        info {
          presentPlayers
          playerCount
          squads {
            squad {
              name
              colorHex
            }
            playerCount
          }
        }
        checkIn(filter: $filter, skip: $skip, sort: $sort, take: $take) {
          count
          totalCount
          nodes {
            id
            comment
            attendance
            parentalPermissionSigned
            hasRentals
            hasViolations
            bbs
            issuedBbs
            gameSquad {
              id
              name
              colorHex
            }
            newTagStatus
            issuedNewTag
            newTagId {
              id
              tagId
              active
            }
            player {
              id
              callSign
              nonLiabilityWaverSigned
              colorHex
              isAdult
              hasViolations
              team {
                name
                abbreviation
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACTIVE_GAME_DAY_RENTAL = gql`
  query($filter: [GameDayPlayerRentalFilter!]!, $sort: [GameDayPlayerRentalSort!]!, $skip: Int, $take: Int) {
    gameDays(filter: {active: {equal: true}}) {
      nodes {
        id
        date
        info {
          presentPlayers
          playerCount
          squads {
            squad {
              name
              colorHex
            }
            playerCount
          }
        }
        rental(filter: $filter, skip: $skip, sort: $sort, take: $take) {
          count
          totalCount
          nodes {
            id
            comment
            attendance
            bbs
            issuedBbs
            gameSquad {
              id
              name
              colorHex
            }
            hasViolations
            player {
              id
              callSign
              isAdult
              nonLiabilityWaverSigned
              colorHex
              hasViolations
              team {
                name
                abbreviation
              }
            }
            issuedItems {
              id
              name
            }
            returnedItems {
              id
              name
            }
            rentalItems {
              id
              name
            }
            rentalPackages {
              id
              name
              items {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;


export const GET_ACTIVE_GAME_DAY_CHRONI = gql`
  query($filter: [GameDayPlayerChronoFilter!]!, $sort: [GameDayPlayerChronoSort!]!, $skip: Int, $take: Int) {
    gameDays(filter: {active: {equal: true}}) {
      nodes {
        id
        date
        info {
          presentPlayers
          playerCount
          squads {
            squad {
              name
              colorHex
            }
            playerCount
          }
        }
        chrono(filter: $filter, skip: $skip, sort: $sort, take: $take) {
          count
          totalCount
          nodes {
            id
            comment
            attendance
            gameSquad {
              id
              name
              colorHex
            }
            hasViolations
            player {
              id
              callSign
              isAdult
              nonLiabilityWaverSigned
              colorHex
              hasViolations
              team {
                name
                abbreviation
              }
            }
            rentalItems {
              id
              name
              weaponType
            }
            rentalPackages {
              id
              name
              items {
                id
                name
                weaponType
              }
            }
            weapons {
              id
              name
              energy
              weaponType
              propulsionType
            }
          }
        }
      }
    }
  }
`;

export const GET_PLAYERS_FOR_GAME_DAY = gql`
  query($filter: [GameDayFilter!]!, $playerFilter: [GameDayPlayerFilter!]!, $sort: [GameDayPlayerSort!]!, $skip: Int, $take: Int) {
    gameDays(filter: $filter) {
      nodes {
        id
        date
        comment
        active
        public
        locked
        players(filter: $playerFilter, skip: $skip, sort: $sort, take: $take) {
          totalCount
          count
          nodes {
            id
            attendanceState
            bbs
            issuedBbs
            rentalPackages {
              id
              name
              amountAvailable
              items {
                id
                name
                amountAvailable
              }
            }
            rentalItems {
              id
              name
              amountAvailable
            }
            newTagStatus
            issuedNewTag
            player {
              id
              callSign
              colorHex
              team {
                id
                name
                abbreviation
              }
            }
            gameSquad {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_SQUADS_FOR_GAME_DAY = gql`
  query {
    squads(sort: [{id: ASC}]) {
      nodes {
        id
        name
        colorHex
      }
    }
  }
`;

export const ADD_GAME_DAYS = gql`
  mutation($input: GameDayCreateInput!) {
    gameDay {
      create(input: $input) {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const EDIT_GAME_DAYS = gql`
  mutation($id: Int!, $input: GameDayEditInput!) {
    gameDay {
      update(id: $id, input: $input) {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const DELETE_GAME_DAYS = gql`
  mutation($ids: [Int!]!) {
    gameDay {
      delete(ids: $ids)
    }
  }
`;

export const ADD_GAME_DAY_PLAYER = gql`
  mutation($gameDayId: Int, $input: GameDayPlayerCreateInput!) {
    gameDay {
      addPlayer(gameDayId: $gameDayId, input: $input) {
        id
      }
    }
  }
`;

export const EDIT_GAME_DAY_PLAYER = gql`
  mutation($id: Int!, $input: GameDayPlayerEditInput) {
    gameDay {
      updatePlayer(id: $id, input: $input) {
        id
        bbs
        rentalPackages {
          id
          name
          amountAvailable
          items {
            id
            name
            amountAvailable
          }
        }
        rentalItems {
          id
          name
          amountAvailable
        }
        player {
          id
          callSign
          colorHex
          team {
            id
            name
          }
        }
      }
    }
  }
`;

export const CHECKIN_MUTATION = gql`
  mutation($gameDayPlayerId: Int!, $checkIn: CheckInEdit!) {
    gameDay {
      checkIn(gameDayPlayerId: $gameDayPlayerId, checkIn: $checkIn)
    }
  }
`;

export const CHRONI_MUTATION = gql`
  mutation($gameDayPlayerId: Int!, $chrono: ChronoEdit!) {
    gameDay {
      chrono(gameDayPlayerId: $gameDayPlayerId, chrono: $chrono)
    }
  }
`;

export const SHOP_MUTATION = gql`
  mutation($gameDayPlayerId: Int!, $shop: ShopEdit!) {
    gameDay {
      shop(gameDayPlayerId: $gameDayPlayerId, shop: $shop)
    }
  }
`;

export const DELETE_GAME_DAY_PLAYER = gql`
  mutation($ids: [Int!]!) {
    gameDay {
      deletePlayer(ids: $ids)
    }
  }
`;

export const ADD_GAMEDAY_PLAYER_WEAPON = gql`
    mutation($id: Int!, $input: GameDayPlayerWeaponCreateInput!) {
      gameDay {
        addPlayerWeapon(gameDayPlayerId: $id, input: $input) {
          id
        }
      }
    }
`;

export const UPDATE_GAMEDAY_PLAYER_WEAPON = gql`
  mutation($id: Int!, $input: GameDayPlayerWeaponEditInput!) {
    gameDay {
      updatePlayerWeapon(id: $id, input: $input) {
        id
      }
    }
  }
`;

export const DELETE_GAMEDAY_PLAYER_WEAPON = gql`
  mutation($id: [Int!]!) {
    gameDay {
      deletePlayerWeapon(ids: $id)
    }
  }
`;
