<div class="flex h-full items-center gap-5">
  <input
    pInputText
    type="tel"
    maxlength="1"
    [id]="name + 0"
    [name]="name"
    [attr.value]="valueInput[0]"
    (keydown)="onInput(0, $event)"
    [ngStyle]="{'color': pickerColor}"
    style="width: 35px;"
    [required]="required"
    [disabled]="disabled"
  >
  ,
  <input
    pInputText
    type="tel"
    maxlength="1"
    [id]="name + 1"
    [name]="name"
    [attr.value]="valueInput[1]"
    (keydown)="onInput(1, $event)"
    [ngStyle]="{'color': pickerColor}"
    style="width: 35px;"
    [required]="required"
    [disabled]="disabled"
  >
  <input
    pInputText
    type="tel"
    maxlength="1"
    [id]="name + 2"
    [name]="name"
    [attr.value]="valueInput[2]"
    (keydown)="onInput(2, $event)"
    [ngStyle]="{'color': pickerColor}"
    style="width: 35px;"
    [required]="required"
    [disabled]="disabled"
  >
</div>
