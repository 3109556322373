import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-combination-lock-wheel',
  templateUrl: './combination-lock-wheel.component.html',
  styleUrl: './combination-lock-wheel.component.scss'
})
export class CombinationLockWheelComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() inputId: string = "combination-lock-wheel";
  @Input() name: string = "combination-lock-wheel";
  @Input() min: number = 0;
  @Input() max: number = 100;

  @Input() color: string = '#fff';

  @Input() required: boolean = false;
  _disabled: boolean = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.form.controls.picker.disable();
    } else {
      this.form.controls.picker.enable();
    }
  }

  @Input()
  get value(): number {
    return this.form.controls.picker.value ?? 0;
  }

  set value(value: number) {
    this.form.controls.picker.setValue(value);
  }

  @Input() onSetValue?: (value: number) => number;

  @Output() valueChange = new EventEmitter<number>();

  @ViewChild('numberPicker', {static: false}) numberPicker!: ElementRef;

  protected form = new FormGroup({
    picker: new FormControl<number>(0)
  });
  private startY: number = 0;
  private endY: number = 0;
  private unsubscribe$ = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    if (this.required) {
      this.form.controls.picker.addValidators(Validators.required);
    }
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(changes => {
        if (changes.picker == null) {
          return;
        }
        let value = changes.picker;

        if (this.onSetValue) {
          const interceptedValue = this.onSetValue(value);
          if (interceptedValue !== value) {
            value = interceptedValue;
            this.form.controls.picker.setValue(value, {emitEvent: false});
          }
        }

        if (value > this.max) {
          value = this.max;
          this.form.controls.picker.setValue(value, {emitEvent: false});
        } else if (value < this.min) {
          value = this.min;
          this.form.controls.picker.setValue(value, {emitEvent: false});
        }
        this.valueChange.emit(value);
      });
  }

  ngAfterViewInit() {
    const pickerElement = this.numberPicker.nativeElement;

    pickerElement.addEventListener('touchstart', this.onTouchStart.bind(this), {passive: true});
    pickerElement.addEventListener('touchend', this.onTouchEnd.bind(this), {passive: true});
    pickerElement.addEventListener('touchmove', this.onTouchMove.bind(this), {passive: false});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
  }

  onTouchMove(event: TouchEvent) {
    event.preventDefault();
  }

  onTouchEnd(event: TouchEvent) {
    this.endY = event.changedTouches[0].clientY;
    this.handleGesture();
  }

  handleGesture() {
    const deltaY = this.startY - this.endY;
    if (deltaY > 30) {
      this.increment();
    } else if (deltaY < -30) {
      this.decrement();
    }
  }

  get previousValue(): number {
    return this.value > this.min ? this.value - 1 : this.max;
  }

  get nextValue(): number {
    return this.value < this.max ? this.value + 1 : this.min;
  }

  increment() {
    if (this.value < this.max) {
      this.value++;
    } else {
      this.value = this.min;
    }
  }

  decrement() {
    if (this.value > this.min) {
      this.value--;
    } else {
      this.value = this.max;
    }
  }
}
