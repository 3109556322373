import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Column} from "src/app/modules/shared/base/component-with-table/extended-table";

@Component({
  selector: 'app-table-caption',
  templateUrl: './table-caption.component.html',
  styleUrl: './table-caption.component.scss'
})
export class TableCaptionComponent {

  @Input() loading = false;
  @Input() count = 0;
  @Input() totalRecords = 0;
  @Input() name = '';
  @Input() countHeader = '';
  @Input() columns?: Column[] = undefined;

  private _hiddenColumns: Column[] = [];
  @Input()
  set hiddenColumns(columns: Column[]) {
    this._hiddenColumns = columns;
    this.hiddenColumnsChange.emit(columns);
  }

  get hiddenColumns(): Column[] {
    return this._hiddenColumns;
  }

  @Output() hiddenColumnsChange: EventEmitter<Column[]> = new EventEmitter<Column[]>();

  @Input() resetSort?: () => void = undefined;
  @Input() resetFilters?: () => void = undefined;
}
