import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GameDayInfo} from "src/app/models/entities/game-day";

@Component({
  selector: 'app-gameday-info',
  templateUrl: './gameday-info.component.html',
  styleUrl: './gameday-info.component.scss'
})
export class GamedayInfoComponent {

  @Input({required: true}) gameDayInfo!: GameDayInfo;
  @Input() filterOrga: boolean = false;
  @Output() filterOrgaChange = new EventEmitter<boolean>();
}
