import {Injectable} from '@angular/core';
import {WeaponType} from "src/app/models/entities/weapon-type.enum";
import {GameDayPlayerWeapon, JouleRule, PropulsionType, WeaponColor} from "src/app/models/entities/chroni";

@Injectable({
  providedIn: 'root'
})
export class JouleRulesService {

  private rules: Map<WeaponType, JouleRule[]> = new Map([
    [WeaponType.BACKUP, [
      {
        propulsionType: [PropulsionType.AEP],
        energyRanges: new Map([
          [WeaponColor.white, {min: 0, max: 0.59}]
        ])
      },
      {
        propulsionType: [PropulsionType.GAS, PropulsionType.SPRING],
        energyRanges: new Map([
          [WeaponColor.white, {min: 0, max: 0.99}]
        ])
      },
      {
        propulsionType: [PropulsionType.HPA],
        energyRanges: new Map([
          [WeaponColor.white, {min: 0, max: 0.9}]
        ])
      }
    ]],
    [WeaponType.RIFLE, [
      {
        propulsionType: [PropulsionType.AEG],
        energyRanges: new Map([
          [WeaponColor.green, {min: 0, max: 0.59}],
        ])
      },
      {
        propulsionType: [PropulsionType.GAS, PropulsionType.SPRING, PropulsionType.SAEG],
        energyRanges: new Map([
          [WeaponColor.green, {min: 0, max: 0.99}],
          [WeaponColor.yellow, {min: 0.99, max: 1.39}],
          [WeaponColor.red, {min: 1.39, max: 1.79}]
        ])
      },
      {
        propulsionType: [PropulsionType.HPA],
        energyRanges: new Map([
          [WeaponColor.green, {min: 0, max: 0.89}],
          [WeaponColor.yellow, {min: 0.89, max: 1.29}],
          [WeaponColor.red, {min: 1.29, max: 1.69}]
        ])
      }
    ]],
    [WeaponType.BOLT_ACTION_SNIPER, [
      {
        propulsionType: [PropulsionType.GAS, PropulsionType.SPRING],
        energyRanges: new Map([
          [WeaponColor.red, {min: 0, max: 2.49}]
        ])
      },
      {
        propulsionType: [PropulsionType.HPA],
        energyRanges: new Map([
          [WeaponColor.red, {min: 0, max: 2.39}]
        ])
      }
    ]]
  ]);

  constructor() {
  }

  getWeaponColor(isAdult: boolean, weapon: GameDayPlayerWeapon): WeaponColor | null {
    if (!isAdult) {
      const restrictedEnergy = 0.59;
      const allowedTypesForU18 = new Map([
        [WeaponType.BACKUP, [PropulsionType.AEP, PropulsionType.SPRING]],
        [WeaponType.RIFLE, [PropulsionType.AEG, PropulsionType.SPRING]],
        [WeaponType.BOLT_ACTION_SNIPER, [PropulsionType.SPRING]]
      ]);

      const allowedPropulsions = allowedTypesForU18.get(weapon.weaponType);

      if (!allowedPropulsions || !allowedPropulsions.includes(weapon.propulsionType) || weapon.energy > restrictedEnergy) {
        return null;
      }
    }

    const weaponRules = this.rules.get(weapon.weaponType);
    if (!weaponRules) return null;

    for (const rule of weaponRules) {
      if (rule.propulsionType.includes(weapon.propulsionType)) {
        for (const [color, range] of rule.energyRanges.entries()) {
          if (weapon.energy >= range.min && weapon.energy <= range.max) {
            return color;
          }
        }
      }
    }

    return null;
  }
}
