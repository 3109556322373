import {Logger} from "src/app/services/logger/logger.service";
import {Injectable} from "@angular/core";
import {ToastService} from "src/app/services/toast/toast.service";
import {IdentityService} from "src/app/services/identity/identity.service";
import {ActivatedRouteSnapshot, Router} from "@angular/router";

const log = new Logger('PermissionGuard')

@Injectable()
export class PermissionGuard {

  constructor(
    private router: Router,
    private identity: IdentityService,
    private toast: ToastService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const permissions = route.data["permission"] as string[];

    const user = await this.identity.getLoggedInUser();
    const validate = permissions ? permissions.some(x => this.identity.hasUserPermission(user, x)) : true;
    if (!validate) {
      log.debug('Permission denied', permissions, user, validate);
      this.toast.warn('common.warning', 'error.permission_denied');
      this.router.navigate(['/']);
    }
    log.debug('Permission granted', permissions, user, validate);
    return validate;
  }
}
