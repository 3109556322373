<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<div *ngIf="sortable; else without" [pSortableColumn]="subColumn ?? sortableColumn">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #without>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>
