import {Player} from "src/app/models/entities/player";
import {RentalItem, RentalPackage} from "src/app/models/entities/rentals";
import {GameDayCheckin} from "src/app/models/entities/checkin";
import {QueryResult} from "src/app/models/entities/query-result";
import {GameDayChrono} from "src/app/models/entities/chroni";
import {Violation} from "src/app/models/entities/violation";

export interface GameDay {
  id: number;
  date: Date;
  comment: string;
  active: boolean;
  public: boolean;
  locked: boolean;
  checkIn: QueryResult<GameDayCheckin>;
  players: QueryResult<GameDayPlayer>;
  rental: QueryResult<GameDayPlayerRental>;
  chrono: QueryResult<GameDayChrono>;
  info: GameDayInfo;
}

export enum AttendanceState {
  NOT_PRESENT = "NOT_PRESENT",
  PRESENT = "PRESENT",
  DELAYED = "DELAYED",
  CANCELED = "CANCELED",
}

export enum NewTagStatus {
  NEW_TAG_NEW_PLAYER = 'NEW_TAG_NEW_PLAYER',
  NEW_TAG_OLD_PLAYER = 'NEW_TAG_OLD_PLAYER',
  NEW_TAG_NEW_PLAYER_UNPAID = 'NEW_TAG_NEW_PLAYER_UNPAID',
  NEW_TAG_OLD_PLAYER_UNPAID = 'NEW_TAG_OLD_PLAYER_UNPAID',
}

export interface Squad {
  id: number;
  name: string;
  colorCodeId: number;
  coalitionId: number;
  colorHex: string;
  players: GameDayPlayer[];
}

export interface GameDayPlayer {
  id: number;
  gameDayId: number;
  gameSquad?: Squad;
  comment: string;
  attendanceState: AttendanceState;
  parentalPermissionSigned: boolean;
  bbs: number[];
  issuedBbs: number[];
  player: Player;
  rentalPackages: RentalPackage[];
  rentalItems: RentalItem[];
  rentals: (RentalPackage | RentalItem)[];
  issuedNewTag: boolean;
  newTagStatus?: NewTagStatus;
  violations: QueryResult<Violation>;
}

export interface GameDayPlayerRental {
  id: number;
  comment: string | undefined;
  attendance: AttendanceState;
  gameSquad?: Squad;
  bbs: number[];
  issuedBbs: number[];
  player: Player;
  issuedItems: RentalItem[];
  returnedItems: RentalItem[];
  rentalPackages: RentalPackage[];
  rentalItems: RentalItem[];
  rentals: (RentalPackage | RentalItem)[];
  itemsToIssue: number;
}

export interface GameDayInfo {
  presentPlayers: number;
  playerCount: number;
  squads: { squad: { name: string, colorHex: string }, playerCount: number }[];
}
