import { Component, Input } from "@angular/core";
import {HideableComponent} from "src/app/modules/shared/base/hideable-compnent/hideable-component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[hideable-td]",
  templateUrl: "./hideable-column.component.html",
  styleUrls: ["./hideable-column.component.scss"]
})
export class HideableColumnComponent<T> extends HideableComponent<T> {
  @Input("hideable-td") override column!: string;

  constructor() {
    super();
  }

}
