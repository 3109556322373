import {gql} from "apollo-angular";

export const GET_ALL_PLAYERS = gql`
  query {
    players(take: 99999) {
      totalCount
      count
      nodes {
        id
        identityId
        callSign
        banned
        comment
        birthdate
        isAdult
        nonLiabilityWaverSigned
        neverPlayed
        colorHex
        team {
          id
          name
          abbreviation
        }
      }
    }
  }
`;

export const GET_PLAYERS = gql`
  query($filter: [PlayerFilter!]!, $sort: [PlayerSort!]!, $skip: Int, $take: Int) {
    players(filter: $filter, skip: $skip, sort: $sort, take: $take) {
      totalCount
      count
      nodes {
        id
        identityId
        callSign
        banned
        comment
        birthdate
        isAdult
        nonLiabilityWaverSigned
        neverPlayed
        orga
        colorHex
        team {
          id
          name
          abbreviation
        }
        aggregatedStats {
          score
          level
          gamesWon
          gamesLost
          kills
          respawns
          kd
          captures
          flags
          bombs
          loot
        }
      }
    }
  }
`;

export const GET_ALL_PLAYERS_FOR_GAME_DAY_ASSIGNMENT = gql`
  query($filter: [PlayerFilter!]!, $sort: [PlayerSort!]!, $gameDayFilter: [GameDayFilter!]!) {
    players(take: 99999, sort: $sort, filter: $filter) {
      totalCount
      count
      nodes {
        id
        callSign
        colorHex
        team {
          id
          name
          abbreviation
        }
      }
    }
    gameDays(filter: $gameDayFilter) {
      nodes {
        players {
          nodes {
            player {
              id
              callSign
            }
          }
        }
      }
    }
  }
`;

export const GET_PLAYERS_WITHOUT_TEAMS = gql`
  query {
    players(filter: { team: { id: { isNull: true } } }, take: 99999) {
      totalCount
      count
      nodes {
        id
        identityId
        callSign
        banned
        comment
        nonLiabilityWaverSigned
        neverPlayed
        team {
          id
        }
      }
    }
  }
`;

export const ADD_PLAYERS = gql`
  mutation($player: PlayerCreateInput!) {
    player {
      createProfile(player: $player) {
        id
        identityId
        callSign
        banned
        comment
        nonLiabilityWaverSigned
        neverPlayed
        team {
          id
        }
      }
    }
  }
`;

export const EDIT_PLAYERS = gql`
  mutation($id: Int!, $change: PlayerEditProfileInput!) {
    player {
      editProfile(id: $id, change: $change) {
        id
        identityId
        callSign
        banned
        comment
        nonLiabilityWaverSigned
        neverPlayed
        team {
          id
        }
      }
    }
  }
`;

export const DELETE_PLAYERS = gql`
  mutation($id: Int!) {
    player {
      deleteProfile(id: $id)
    }
  }
`;
