import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastOptions } from 'src/app/models/utils/toast-options';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private translate: TranslateService,
    private messageService: MessageService
  ) { }

  private toast(type: string, summary: string, detail?: string, options?: ToastOptions) {
    this.messageService.add({
      severity: type,
      summary: this.translate.instant(summary),
      detail: detail ? this.translate.instant(detail) : undefined,
      life: options?.life,
      sticky: options?.sticky,
      closable: options?.closable
    });
  }

  success(summary: string, detail?: string, options?: ToastOptions) {
    this.toast('success', summary, detail, options);
  }

  info(summary: string, detail?: string, options?: ToastOptions) {
    this.toast('info', summary, detail, options);
  }

  warn(summary: string, detail?: string, options?: ToastOptions) {
    this.toast('warn', summary, detail, options);
  }

  error(summary: string, detail?: string, options?: ToastOptions) {
    this.toast('error', summary, detail, options);
  }
}
