<div class="number-picker" #numberPicker pFocusTrap>
  <form [formGroup]="form" class="number-picker-display">
    <div class="number-picker-value">
      <button type="button" (click)="decrement()" tabindex="-1">{{ previousValue }}</button>
    </div>
    <input
      [id]="inputId"
      [name]="name"
      class="number-picker-input"
      type="number"
      [min]="min"
      [max]="max"
      formControlName="picker"
      [ngStyle]="{'color': color}"
    >
    <div class="number-picker-value">
      <button type="button" (click)="increment()" tabindex="-1">{{ nextValue }}</button>
    </div>
  </form>
</div>
