import {Component, Input} from "@angular/core";
import {HideableComponent} from "src/app/modules/shared/base/hideable-compnent/hideable-component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[hideable-th]",
  templateUrl: "./hideable-header.component.html",
  styleUrls: ["./hideable-header.component.scss"]
})
export class HideableHeaderComponent<T> extends HideableComponent<T>  {
  @Input("hideable-th") override column!: string | string[];
  @Input() sortable: boolean = false;

  get sortableColumn() {
    return Array.isArray(this.column) ? this.column[0] : this.column;
  }

  constructor() {
    super();
  }

}
