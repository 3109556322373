import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Player} from "src/app/models/entities/player";

@Injectable({
  providedIn: 'root'
})
export class PlayerProfileDialogService {

  player$ = new BehaviorSubject<Player | undefined>(undefined);

  constructor() {
  }

  openPlayerProfile(player: Player) {
    this.player$.next(player);
  }

  closePlayerProfile() {
    this.player$.next(undefined);
  }
}
