import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {TableColumn, TableColumnType} from "src/app/modules/shared/components/table/table.model";
import {ToastService} from "src/app/services/toast/toast.service";
import {ControlContainer, NgForm, NgModelGroup} from "@angular/forms";
import {AttendanceState} from "src/app/models/entities/game-day";
import {TranslateService} from "@ngx-translate/core";
import {CustomColumnDirective} from "src/app/modules/shared/components/table-column/custom-column.directive";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-table-column',
  templateUrl: './table-column.component.html',
  styleUrl: './table-column.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: NgModelGroup}]
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
export class TableColumnComponent<T> implements OnInit {

  @Input({required: true}) column!: TableColumn;
  @Input({required: true}) value!: any;
  @Input({required: true}) form!: NgForm;
  protected readonly TableColumnType = TableColumnType;
  @Input() isEditing: boolean = false;
  @Input() isEditingNew: boolean = false;

  @ContentChild(CustomColumnDirective, {read: TemplateRef}) customColumnTemplate!: TemplateRef<{
    $implicit: T,
    column: TableColumn
  }>;

  protected attendanceStates = Object.values(AttendanceState).map(value => {
    return {label: this.t.instant(`attendance_state.${value}`), value}
  });
  protected dropdownOptions: { [key: string]: Observable<any> } = {};
  protected chipSelectOptions: { [key: string]: Observable<any> } = {};
  protected chipSelectFavOptions: { [key: string]: Observable<any> } = {};

  constructor(
    private toast: ToastService,
    private t: TranslateService,
  ) {
  }

  ngOnInit() {
    if (this.column.type === TableColumnType.DROPDOWN) {
      this.openDropdown();
    }
    if (this.column.type === TableColumnType.CHIPSELECT) {
      this.openChipSelect();
    }
  }

  setPasswordColumnVisibility(column: TableColumn, visible: boolean) {
    if (!column.passwordOptions) {
      column.passwordOptions = {};
    }

    column.passwordOptions.visible = visible;
  }

  copy(val: string) {
    navigator.clipboard.writeText(val).then(() => {
      this.toast.info('common.copied', 'common.copied_to_clipboard');
    });
  }

  protected openDropdown() {
    const fallbackOptions = this.column.dropdownOptions?.options ?? [];

    if (!this.column.dropdownOptions?.optionGetter) {
      this.dropdownOptions[this.column.name] = of(fallbackOptions);
      return;
    }
    this.dropdownOptions[this.column.name] = this.column.dropdownOptions.optionGetter();
  }

  protected openChipSelect() {
    const fallbackOptions = this.column.chipSelectOptions?.items ?? [];

    if (!this.column.chipSelectOptions?.itemGetter) {
      this.chipSelectOptions[this.column.name] = of(fallbackOptions);
      return;
    }
    this.chipSelectOptions[this.column.name] = this.column.chipSelectOptions.itemGetter();

    const fallbackFavOptions = this.column.chipSelectOptions?.quickSelectionItems ?? [];

    if (!this.column.chipSelectOptions?.quickSelectionItemGetter) {
      this.chipSelectFavOptions[this.column.name] = of(fallbackFavOptions);
      return;
    }
    this.chipSelectFavOptions[this.column.name] = this.column.chipSelectOptions.quickSelectionItemGetter();
  }

  protected readonly Object = Object;
}
