import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IdentityService} from "src/app/services/identity/identity.service";
import {IdentityUser} from "src/app/models/entities/identity-user";
import {Subscription} from "rxjs";
import {TerminalService} from "primeng/terminal";
import {SettingsService} from "src/app/services/settings/settings.service";
import {SpinnerService} from "src/app/services/spinner/spinner.service";
import {PersonalSettingsService} from "src/app/modules/shared/services/personal-settings.service";

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrl: './terminal.component.scss'
})
export class TerminalComponent implements OnInit, OnDestroy {

  private _visible: boolean = false;

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
    if (value) {
      this.setPrompt();
    }
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public welcomeMessage = '';
  protected prompt: string = 'admin-tool:~$';
  protected user?: IdentityUser;
  private subscription: Subscription;
  public frontendVersion: string = "0.0.0.0";

  commands: {
    name: string,
    alias?: string,
    description: string,
    command: () => string
  }[] = [
    {
      name: 'ping',
      description: 'Pong!',
      command: () => 'pong'
    },
    {
      name: 'exit',
      description: 'Closes the console',
      command: () => {
        this.visible = false;
        return 'bye';
      }
    },
    {
      name: 'Kroete',
      alias: 'kroete',
      description: 'Opens Kroete`s github',
      command: () => {
        window.location.href = 'https://github.com/Kroete1979';
        return 'bye';
      }
    },
    {
      name: 'Donkoleon',
      alias: 'donkoleon',
      description: 'Opens Donkoleon`s github',
      command: () => {
        window.location.href = 'https://github.com/LeonHaemmerling';
        return 'bye';
      }
    },
    {
      name: 'edraft',
      description: 'Opens edraft`s website',
      command: () => {
        window.location.href = 'https://www.sh-edraft.de/';
        return 'bye';
      }
    },
    {
      name: 'more',
      description: 'Find out more about our projects',
      command: () => {
        window.location.href = 'https://www.area49-shop.de/entwicklung';
        return 'bye';
      }
    },
    {
      name: 'spinner',
      description: 'toggle spinner',
      command: () => {
        this.spinner.toggle();
        return 'Toggled';
      }
    },
    {
      name: 'nfc disable',
      description: 'disable nfc listener',
      command: () => {
        this.personalSettings.setSetting('disableNfcListener', true);
        return 'Disabled, reload page to apply changes';
      }
    },
    {
      name: 'nfc enable',
      description: 'enable nfc listener',
      command: () => {
        this.personalSettings.setSetting('disableNfcListener', false);
        return 'Enabled, reload page to apply changes';
      }
    },
  ]

  constructor(
    private auth: IdentityService,
    private terminalService: TerminalService,
    private settings: SettingsService,
    private personalSettings: PersonalSettingsService,
    private spinner: SpinnerService,
  ) {
    this.commands = [
      {
        name: 'help',
        description: 'Shows this help message',
        command: () => {
          let message = 'Available commands:\n\n';

          this.commands.forEach((command) => {
            message += `${command.name}${command.alias ? ` (${command.alias})` : ''} - ${command.description}\n`;
          });

          message += '\n';

          return message;
        }
      },
      ...this.commands
    ];

    this.subscription = this.terminalService.commandHandler.subscribe((command) => {
      const response = this.commands.find(x => x.name === command || x.alias && x.alias === command)?.command() ?? 'Unknown command: ' + command;
      this.terminalService.sendResponse(response);
    });
  }

  ngOnInit() {
    this.frontendVersion = this.settings.getWebVersion();
    this.setWelcomeMessage();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setPrompt() {
    if (this.auth.$identityUser.value) {
      this.user = this.auth.$identityUser.value;
      this.prompt = `${this.user.Firstname}@${this.prompt}`;
    }
  }

  setWelcomeMessage() {
    this.welcomeMessage = `
               ((         Admin Tool @ Area49
               ( \`)      ------------------------------
               ; / ,      Version: ${this.frontendVersion}
              /  \\/       Developed by: Kroete, Donkoleon & edraft
             /  |         Started: 28. Feb. 2024, 7:38 PM MEZ
            /  ~/        Used languages: TypeScript, HTML, SCSS, C# & SQL
           / )  )
    ___// | /
    \`--'  \\_~-,
  `;
  }
}
