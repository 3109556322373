import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Logger} from "src/app/services/logger/logger.service";

const log = new Logger('SpinnerService')

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  showSpinnerState$ = new BehaviorSubject<boolean>(false);

  show() {
    log.trace('Show spinner');
    this.showSpinnerState$.next(true);
    this.toggleMain();
  }

  hide() {
    log.trace('Hide spinner');
    this.showSpinnerState$.next(false);
    this.toggleMain();
  }

  toggle() {
    this.showSpinnerState$.next(!this.showSpinnerState$.value);
  }

  private toggleMain() {
    // main can be disabled because the spinner is modal. When the spinner is active the menu does not work anyway.
    const elements = document.getElementsByTagName('main');
    if (!elements || elements.length == 0) {
      return;
    }
    const element = elements[0];
    if (!this.showSpinnerState$.value && element.classList.contains('disabled')) {
      element.classList.remove('disabled');
      return;
    }
    if (this.showSpinnerState$.value) {
      element.classList.add('disabled');
    }
  }
}
