import {Component, HostListener, Input} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Shortcut} from "src/app/modules/shared/shortcut/shortcut.model";
import {SelectedItemsService} from "src/app/modules/shared/select-items/selected-items.service";

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrl: './shortcut.component.scss'
})
export class ShortcutComponent {

  @Input() headerTranslationKey!: string;
  @Input({required: true}) shortcuts!: Shortcut[];
  @Input() breadcrumbs?: MenuItem[];

  home = {icon: 'pi pi-home', routerLink: '/'};

  constructor(private selectionService: SelectedItemsService) {

  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.shortcuts.forEach(shortcut => {
      if (this.isShortcutPressed(shortcut.key, event)) {
        shortcut.command(this.selectionService.items);
        event.preventDefault();
      }
    });
  }

  private isShortcutPressed(shortcutKey: string, event: KeyboardEvent): boolean {
    const keys = shortcutKey.split('+').map(key => key.trim().toLowerCase());
    const keySet = new Set(keys);
    if (!event.key) {
      return false;
    }
    const eventKey = event.key.toLowerCase();

    const requiredKeys: { [key: string]: boolean } = {
      ctrl: keySet.has('ctrl') || keySet.has('control'),
      alt: keySet.has('alt'),
      shift: keySet.has('shift'),
      meta: keySet.has('meta') || keySet.has('cmd') || keySet.has('command'),
      altGr: keySet.has('altgraph'),
    };

    const pressedKeys: { [key: string]: boolean } = {
      ctrl: event.ctrlKey,
      alt: event.altKey,
      shift: event.shiftKey,
      meta: event.metaKey,
      altGr: event.getModifierState('AltGraph'),
    };

    // Prüfe ob alle erforderlichen Modifier-Tasten gedrückt sind
    for (const key in requiredKeys) {
      if (requiredKeys[key] !== pressedKeys[key]) {
        return false;
      }
    }

    // Prüfe die Haupttaste, die keine Modifier-Taste ist
    const nonModifierKeys = Array.from(keySet).filter(
      key => !['ctrl', 'control', 'alt', 'shift', 'meta', 'cmd', 'command', 'altgraph'].includes(key)
    );

    return nonModifierKeys.includes(eventKey);
  }
}
