<div class="table-caption-column-select">
  <p-multiSelect styleClass="hidden-columns-select" [options]="columns" [(ngModel)]="hiddenColumns" optionLabel="name"
                 [filter]=false (onPanelShow)="isPanelOpen = true" (onPanelHide)="isPanelOpen = false" >
    <ng-template pTemplate="selectedItems">
      <p-button *ngIf="!showHiddenColumnLabels; else hiddenColumnLabels" tooltipPosition="left"
                pTooltip="{{'common.hidden_columns' | translate}}"
                icon="pi pi-eye-slash" class="icon-btn btn" [ngClass]="{'active-while-panel-open': isPanelOpen}"></p-button>

      <ng-template #hiddenColumnLabels>
        <div *ngFor="let item of visibleHiddenColumns; let i = index;">
          <div *ngIf="item.name">
            {{ 'common.' + item.name | translate }}<span *ngIf="i < columns.length-1">,</span>
          </div>
          <div *ngIf="!item.name">{{ 'common.hidden_columns' | translate }}</div>
        </div>
        <div
          *ngIf="!visibleHiddenColumns || visibleHiddenColumns.length === 0">{{ 'common.hidden_columns' | translate }}
        </div>
      </ng-template>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <div>{{ 'common.' + item.name | translate }}</div>
    </ng-template>
  </p-multiSelect>
</div>
