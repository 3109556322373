import {Injectable} from '@angular/core';
import {catchError} from "rxjs/operators";
import {Apollo, gql} from "apollo-angular";
import {firstValueFrom, map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PersonalSettingsService {

  constructor(
    private apollo: Apollo,
  ) {
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  async getSetting(key: string): Promise<any> {
    return firstValueFrom(this.apollo.query<{ setting: string }>({
      query: gql`
        query($key: String!) {
          setting(key: $key)
        }
      `,
      variables: {
        key
      }
    }).pipe(
      map(setting => setting.data.setting),
      catchError(err => {
        throw err;
      })));
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setSetting(key: string, value: any) {
    this.apollo.mutate({
      mutation: gql`
        mutation($key: String!, $value: _Any!) {
          setting(key: $key, value: $value)
        }
      `,
      variables: {
        key,
        value
      }
    }).pipe(
      catchError(err => {
        throw err;
      }))
    .subscribe(() => {
    });
  }
}
