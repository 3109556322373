import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {forkJoin, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {AppAndVersionSettings, AppSettings, VersionSettings} from "src/app/models/config/app-settings";

@Injectable({
  providedIn: "root"
})
export class SettingsService {
  appSettings!: AppSettings;
  versionSettings!: VersionSettings;

  constructor(
    private http: HttpClient,
  ) {
  }

  loadSettings(): Promise<AppAndVersionSettings> {
    return new Promise((resolve, reject) => {
      forkJoin([
        this.http.get<AppSettings>("/assets/config.json")
          .pipe(catchError(error => {
            reject(error);
            return throwError(() => error);
          })),
        this.http.get<VersionSettings>("/assets/version.json")
          .pipe(catchError(error => {
            reject(error);
            return throwError(() => error);
          }))
      ]).subscribe(settings => {
        this.appSettings = settings[0];
        this.versionSettings = settings[1];
        resolve({...settings[0], ...settings[1]});
      });
    });
  }

  public getPrivacyURL(): string {
    if (!this.appSettings || !this.appSettings.PrivacyURL) {
      // console.error("PrivacyURL is not set!");
      return "/404";
    }
    return this.appSettings.PrivacyURL;
  }

  public getImprintURL(): string {
    if (!this.appSettings || !this.appSettings.ImprintURL) {
      // console.error("ImprintURL is not set!");
      return "/404";
    }
    return this.appSettings.ImprintURL;
  }

  public getWebVersion(): string {
    if (!this.versionSettings || !this.versionSettings.WebVersion) {
      console.error("WebVersion is not set!");
      return "0.0.0.0";
    }
    return this.versionSettings.WebVersion;
  }
}

