import { Directive, HostBinding, Input } from "@angular/core";
import {ExtendedTable} from "src/app/modules/shared/base/component-with-table/extended-table";
import {TableComponent} from "src/app/modules/shared/components/table/table.component";

@Directive()
export class HideableComponent<T> {
  @HostBinding("class.hidden-column")
  get hidden() {
    const column = this.subColumn ?? this.column;
    if (Array.isArray(column)) {
      return column.every(c => !(this.parent?.isColumnVisible(c) ?? true));
    }
    return !(this.parent?.isColumnVisible(column) ?? true);
  }

  @Input() column!: string | string[];
  @Input() subColumn!: string | undefined| null;
  @Input() parent!: TableComponent<T> | ExtendedTable<T>;
}
