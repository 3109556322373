<ng-container *ngIf="result">
  <p-contextMenu #cm [model]="contextMenu"/>
  <p-table
    #dt
    [value]="result.nodes"
    [loading]="loading"
    [dataKey]="dataKey"
    [totalRecords]="result.totalCount"
    [lazy]="true"
    (onLazyLoad)="nextPage($event)"
    [(first)]="skip" [(rows)]="take"
    [sortField]="sortField" [sortOrder]="sortOrder"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [paginator]="hidePaginator !== false"

    editMode="row"

    [responsiveLayout]="responsiveLayout"
    columnResizeMode="expand"
    [breakpoint]="'900px'"
    [rowHover]="true"

    [contextMenu]="contextMenu ? cm : undefined"
    [(contextMenuSelection)]="contextMenuItem"
  >
    <ng-template pTemplate="caption">
      <div class="table-caption">
        <div *ngIf="countHeaderTranslation" class="table-caption-table-info">
          <div class="table-caption-text">
            <ng-container *ngIf="!loading">{{ skip > 0 ? skip : 1 }} {{ 'common.until' | translate }} {{ skip + result.count }} {{ 'common.of' | translate }}
              {{ result.totalCount }}
            </ng-container>
            {{ countHeaderTranslation | translate }}
          </div>
        </div>

        <div *ngIf="sortable || filterable" class="table-caption-btn-wrapper btn-wrapper">
          <div class="flex gap-5">
            <ng-container *ngIf="hasPermissions.add">
              <ng-container *ngIf="addActionTemplate; else addBtn;">
                <ng-container *ngTemplateOutlet="addActionTemplate;"></ng-container>
              </ng-container>
              <ng-template #addBtn>
                <p-button *ngIf="add.observed && editable" tooltipPosition="left"
                          pTooltip="{{'common.add' | translate}}" class="icon-btn btn"
                          icon="pi pi-plus" (click)="addNewNode()"
                          [disabled]="isEditingNew">
                </p-button>
              </ng-template>
            </ng-container>

            <div class="flex">
              <app-multi-select-hidden-columns *ngIf="hideableColumns && hiddenColumns" [table]="name"
                                               [columns]="hideableColumns"
                                               [(hiddenColumns)]="hiddenColumns"></app-multi-select-hidden-columns>
              <p-button *ngIf="sortable" tooltipPosition="left" pTooltip="{{'common.reset_sort' | translate}}"
                        icon="pi pi-sort-alt-slash"
                        class="icon-btn btn" (click)="resetSort(dt)">
              </p-button>
              <p-button *ngIf="filterable" tooltipPosition="left" pTooltip="{{'common.reset_filters' | translate}}"
                        icon="pi pi-filter-slash"
                        class="icon-btn btn" (click)="resetFilters()">
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let column of columns" [hideable-th]="column.label" [parent]="this"
            [sortable]="column.sortable ?? false"
            [ngClass]="column.size ? 'table-header-' + column.size : ''">
          <div class="table-header-label">
            <div class="table-header-text">{{ column.translation | translate }}</div>
            <p-sortIcon *ngIf="column.sortable" [field]="column.label" class="table-header-icon"></p-sortIcon>
          </div>
        </th>
        <th *ngIf="customActionTemplate || edit.observed || delete.observed" class="table-header-actions">
          <div class="table-header-label">
            <div class="table-header-text">{{ 'common.actions' | translate }}</div>
          </div>
        </th>
      </tr>

      <tr>
        <ng-container *ngFor="let column of columns">
          <th *ngIf="column.fuzzyFilterColumns && column.name === column.fuzzyFilterColumns[0]"
              [hideable-th]="column.fuzzyFilterColumns" [attr.colspan]="getFuzzyColspan(column.fuzzyFilterColumns)"
              [parent]="this">
            <form [formGroup]="filterForm">
              <input type="text" pInputText formControlName="fuzzy" placeholder="{{'checkin.fuzzy' | translate}}">
            </form>
          </th>
          <th *ngIf="!column.fuzzyFilterColumns" [hideable-th]="column.label" [parent]="this"
              [ngClass]="{'table-header-{{column.size}}': column.size}">
            <form [formGroup]="filterForm">
              <ng-container
                *ngIf="column.filterable && (column.type === TableColumnType.STRING || column.type === TableColumnType.COMMENT || column.type === TableColumnType.ID || column.type === TableColumnType.PLAYER_ID)">
                <input type="text" pInputText [formControlName]="column.name"
                       placeholder="{{ column.translation | translate}}">
              </ng-container>
              <ng-container
                *ngIf="column.filterable && (column.type === TableColumnType.NUMBER)">
                <input type="number" min="0" pInputText [formControlName]="column.name"
                       placeholder="{{ column.translation | translate}}">
              </ng-container>
              <ng-container *ngIf="column.filterable && column.type === TableColumnType.BOOLEAN">
                <p-checkbox [binary]="true" [formControlName]="column.name">
                </p-checkbox>
              </ng-container>
              <ng-container *ngIf="column.filterable && column.type === TableColumnType.DATE">
                <input type="text" pInputText [formControlName]="column.name"
                       placeholder="{{'common.date' | translate}}">
              </ng-container>
              <ng-container *ngIf="column.filterable && column.type === TableColumnType.TEAM">
                <p-dropdown formControlName="team" dataKey="id"
                            [options]="dropdownOptions[column.name] | async"
                            optionLabel="name"
                            optionValue="name"
                            placeholder="{{'common.team' | translate}}" [filter]="true" filterBy="name"
                            (onShow)="openDropdown(column)"></p-dropdown>
              </ng-container>
              <ng-container *ngIf="column.filterable && column.type === TableColumnType.ATTENDANCE">
                <p-dropdown formControlName="attendance" dataKey="id"
                            [options]="dropdownOptions[column.name] | async"
                            optionLabel="name"
                            optionValue="name"
                            placeholder="{{'common.team' | translate}}" [filter]="true" filterBy="name"
                            (onShow)="openDropdown(column)"></p-dropdown>
              </ng-container>
            </form>
          </th>
        </ng-container>
        <th *ngIf="customActionTemplate || edit.observed || delete.observed"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
      <tr
        [pEditableRow]="editable ? item : undefined"
        [pContextMenuRow]="contextMenu ? item : undefined"
        [ngClass]="{'disabled': rowDisabled ? rowDisabled(item) : false, 'pointer': !!onRowClick}"
        (click)="onRowClick ? onRowClick(item) : undefined"
        ngForm #form="ngForm">
        <td *ngFor="let column of columns" [hideable-th]="column.label" [parent]="this">
          <span class="p-column-title">{{ column.translation | translate }}:</span>
          <app-table-column
            [column]="column" [value]="column.valueGetter ? column.valueGetter(item) : item"
            [isEditing]="isEditing"
            [isEditingNew]="isEditingNew"
            [form]="form" ngModelGroup="{{column.name}}"
          >
            <ng-template customColumn let-item>
              <ng-container
                *ngTemplateOutlet="customColumnTemplate; context: { $implicit: item, column: column }"></ng-container>
            </ng-template>
          </app-table-column>
        </td>
        <td *ngIf="customActionTemplate || add.observed || edit.observed || delete.observed">
          <span class="p-column-title">{{ 'common.actions' | translate }}:</span>

          <div class="btn-wrapper">
            <ng-container *ngIf="customActionTemplate">
              <ng-container *ngTemplateOutlet="customActionTemplate; context: { $implicit: item }"></ng-container>
            </ng-container>

            <ng-container *ngIf="hasPermissions.edit">
              <ng-container *ngIf="editActionTemplate; else editBtn;">
                <ng-container *ngTemplateOutlet="editActionTemplate; context: { $implicit: item }"></ng-container>
              </ng-container>
              <ng-template #editBtn>
                <p-button *ngIf="edit.observed && !editing" pInitEditableRow class="btn icon-btn" icon="pi pi-pencil"
                          (click)="$event.stopPropagation(); onRowEditInit(item, ri)" [disabled]="rowDisabled && rowDisabled(item)"></p-button>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="hasPermissions.delete">
              <ng-container *ngIf="deleteActionTemplate; else deleteBtn;">
                <ng-container *ngTemplateOutlet="deleteActionTemplate; context: { $implicit: item }"></ng-container>
              </ng-container>
              <ng-template #deleteBtn>
                <p-button *ngIf="delete.observed && !editing" class="btn icon-btn danger-icon-btn" icon="pi pi-trash"
                          (click)="$event.stopPropagation(); delete.emit(item)" [disabled]="rowDisabled && rowDisabled(item)"></p-button>
              </ng-template>
            </ng-container>

            <p-button *ngIf="editing" pSaveEditableRow class="btn icon-btn"
                      icon="pi pi-check-circle" (click)="$event.stopPropagation(); onRowEditSave(item, ri)"
                      [disabled]="form.invalid ?? false"></p-button>
            <p-button *ngIf="editing" pCancelEditableRow class="btn icon-btn danger-icon-btn"
                      icon="pi pi-times-circle" (click)="$event.stopPropagation(); onRowEditCancel(ri)"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr></tr>
      <tr>
        <td colspan="10">{{ 'common.no_entries_found' | translate }}</td>
      </tr>
      <tr></tr>
    </ng-template>
  </p-table>
</ng-container>
