import {Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {FormGroup, NgForm} from "@angular/forms";

@Directive({
  selector: 'ng-template[appEditSidebarHeader]'
})
export class EditSidebarHeaderDirective {
}

@Directive({
  selector: 'ng-template[appEditSidebarContent]'
})
export class EditSidebarContentDirective {
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
@Component({
  selector: 'app-edit-sidebar',
  templateUrl: './edit-sidebar.component.html',
  styleUrl: './edit-sidebar.component.scss'
})
export class EditSidebarComponent {

  protected _visible: boolean = false;

  @Input() header!: string;
  @Input() form!: FormGroup<any> | NgForm;
  @Input()
  get visible() {
    return this._visible;
  }

  set visible(v: boolean) {
    this._visible = v;
    this.visibleChange.emit(v);
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() save: EventEmitter<void> = new EventEmitter();

  @ContentChild(EditSidebarHeaderDirective, {read: TemplateRef}) headerTemplate!: TemplateRef<any>;
  @ContentChild(EditSidebarContentDirective, {read: TemplateRef}) contentTemplate!: TemplateRef<any>;
}
