import {Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {IdentityUser} from "src/app/models/entities/identity-user";
import {environment} from "src/environments/environment";
import {User} from "src/app/models/entities/user";
import {Apollo} from "apollo-angular";
import {GET_LOGGED_IN_USER} from "src/app/models/graphql/users.query";
import {ApolloQueryResult} from "@apollo/client/core";


@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  $identityUser = new BehaviorSubject<IdentityUser | null>(null);

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
  ) {
  }

  getLoggedInIdentityUser(): Observable<IdentityUser> {
    return this.http.get<IdentityUser>(`${environment.auth.authority}/connect/userinfo`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  }

  _getLoggedInUser(): Observable<ApolloQueryResult<{ user: User }>> {
    return this.apollo.query<{ user: User }>({
      query: GET_LOGGED_IN_USER,
    });
  }

  async getLoggedInUser(): Promise<User> {
    const response = await lastValueFrom(this._getLoggedInUser());
    if (!response?.data?.user) {
      throw new Error('User not found');
    }
    return response.data.user;
  }

  hasUserPermission(user: User, permission: string): boolean {
    return user.roles.map(x => x.permissions?.map(x => x.name).flat()).flat().includes(permission);
  }
}
