import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'FirstLetter'
})
export class FirstLetterPipe implements PipeTransform {

  transform(value: string): unknown {
    if (value.length == 0) {
      return '';
    }
    return value[0];
  }

}
