<ng-container *ngIf="column.type === TableColumnType.CUSTOM">
  <ng-container *ngTemplateOutlet="customColumnTemplate; context: { $implicit: value, column: column }"></ng-container>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.ID">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input"
        pInputText type="text"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable || isEditingNew"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      {{ value[column.name] }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.STRING">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input"
        pInputText type="text"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      {{ value[column.name] }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.PASSWORD">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <div class="flex w-full justify-center items-center">
        <div *ngIf="!column.passwordOptions?.visible" class="flex w-full justify-center items-center">
          <input
            [name]="column.name"
            class="table-edit-input" pInputText type="text"
            [ngModel]="'**************'"
            [disabled]="!column.editable"
            [required]="column.required ?? false">
          <p-button class="btn icon-btn" icon="pi pi-eye-slash"
                    (click)="setPasswordColumnVisibility(column, true)"></p-button>
        </div>

        <div *ngIf="column.passwordOptions?.visible" class="flex w-full justify-center items-center">
          <input
            [name]="column.name"
            class="table-edit-input"
            pInputText type="text"
            [(ngModel)]="value[column.name]"
            [disabled]="!column.editable"
            [required]="column.required ?? false">
          <p-button class="btn icon-btn" icon="pi pi-eye"
                    (click)="setPasswordColumnVisibility(column, false)"></p-button>
        </div>
        <p-button class="btn icon-btn" icon="pi pi-copy" (click)="copy(value[column.name])"></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="output">
      <div class="flex w-full justify-center items-center">
        <div *ngIf="!column.passwordOptions?.visible" class="flex w-full justify-center items-center">
          <input
            [name]="column.name"
            class="table-edit-input"
            pInputText type="text"
            [ngModel]="'**************'"
            disabled>
          <p-button class="btn icon-btn" icon="pi pi-eye-slash"
                    (click)="setPasswordColumnVisibility(column, true)"></p-button>
        </div>

        <div *ngIf="column.passwordOptions?.visible" class="flex w-full justify-center items-center">
          <input
            [name]="column.name"
            class="table-edit-input"
            pInputText type="text"
            [(ngModel)]="value[column.name]"
            disabled>
          <p-button class="btn icon-btn" icon="pi pi-eye"
                    (click)="setPasswordColumnVisibility(column, false)"></p-button>
        </div>
        <p-button class="btn icon-btn" icon="pi pi-copy" (click)="copy(value[column.name])"></p-button>
      </div>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.NUMBER">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input"
        pInputText type="number" min="0"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      <span *ngIf="column.valuePipe">{{ column.valuePipe(value) }}<span *ngIf="column.currency">{{ column.currency }}</span></span>
      <span *ngIf="!column.valuePipe">{{ value[column.name] }} <span *ngIf="column.currency">{{ column.currency }}</span></span>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.BOOLEAN">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <p-checkbox
        [name]="column.name"
        [binary]="true"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
      </p-checkbox>
    </ng-template>
    <ng-template pTemplate="output">
      <p-checkbox
        [name]="column.name"
        [binary]="true"
        [(ngModel)]="value[column.name]"
        [disabled]="true">
      </p-checkbox>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.DATE">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <p-calendar
        [name]="column.name"
        class="table-edit-input"
        dateFormat="yy-mm-dd"
        [showIcon]="true"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false"></p-calendar>
    </ng-template>
    <ng-template pTemplate="output">
      {{ value[column.name] }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.PLAYER_ID">
  <app-player-id-column *ngIf="value; else defaultPlayer" [player]="value"></app-player-id-column>
  <ng-template #defaultPlayer>
    <span [style]="'color: ' + value?.colorHex + '; font-weight: bold;'">{{ value[column.name] }}</span>
  </ng-template>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.PLAYER_CALL_SIGN">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <input
        [name]="column.name"
        class="table-edit-input"
        pInputText type="text"
        [(ngModel)]="value.callSign"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
    </ng-template>
    <ng-template pTemplate="output">
      <span *ngIf="value.team">[{{ value.team.abbreviation }}]</span> {{ value.callSign }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.COMMENT">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <div class="flex gap-5">
        <input
          [name]="column.name"
          class="table-edit-input" pInputText
          type="text"
          [(ngModel)]="value[column.name]"
          [disabled]="!column.editable"
          [required]="column.required ?? false">
        <p-dropdown
          styleClass="h-full"
          class="flex-1"
          *ngIf="column.commentPresets"
          [name]="column.name + 'preset'"
          [(ngModel)]="value[column.name]"
          optionLabel="id"
          [options]="column.commentPresets"
          [showClear]="true"
        >
          <ng-template let-value pTemplate="item">
            {{value}}
          </ng-template>
        </p-dropdown>
      </div>
    </ng-template>
    <ng-template pTemplate="output">
      <span *ngIf="value[column.name] && value[column.name] !== ''; else noComment">{{ value[column.name] }}</span>
      <ng-template #noComment>...</ng-template>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.DROPDOWN">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <span *ngIf="!column.dropdownOptions; else dropdown">Options not set</span>
      <ng-template #dropdown>
        <p-dropdown
          [name]="column.name"
          [options]="dropdownOptions[column.name] | async"
          [(ngModel)]="value[column.name]"
          [showClear]="column.dropdownOptions?.clearable"
          [dataKey]="column.dropdownOptions?.dataKey"
          [optionLabel]="column.dropdownOptions?.optionLabel"
          [optionValue]="column.dropdownOptions?.optionValue"
          [placeholder]="column.dropdownOptions?.placeholder"
          [filter]="column.dropdownOptions?.filterable"
          [filterBy]="column.dropdownOptions?.filterBy"
          [disabled]="!column.editable"
          [required]="column.required ?? false"
          (onShow)="openDropdown()">
        </p-dropdown>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="output">
      <span *ngIf="column.valuePipe">{{ column.valuePipe(value) }}</span>
      <span
        *ngIf="!column.valuePipe && value[column.name] && value[column.name] !== ''">{{ value[column.name] }}</span>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.CHIPSELECT">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <app-chip-select
        *ngIf="column.chipSelectOptions"
        [optionLabel]="column.chipSelectOptions.itemLabel"
        [name]="column.name"
        [items]="chipSelectOptions[column.name] | async"
        [quickSelectionItems]="chipSelectFavOptions[column.name] | async"
        [(selectedItems)]="value[column.name]"
        [addDialogHeaderTKey]="column.chipSelectOptions.addDialogHeaderTKey ?? ''"
        [addDropdownPlaceholderTKey]="column.chipSelectOptions.addDropdownPlaceholderTKey?? ''"
        [disabled]="!column.editable"
        (onShow)="openChipSelect()"
      ></app-chip-select>
    </ng-template>
    <ng-template pTemplate="output">
      <app-chip-select
        *ngIf="column.chipSelectOptions"
        [optionLabel]="column.chipSelectOptions.itemLabel"
        [name]="column.name"
        [items]="chipSelectOptions[column.name] | async"
        [quickSelectionItems]="chipSelectFavOptions[column.name] | async"
        [(selectedItems)]="value[column.name]"
        [addDialogHeaderTKey]="column.chipSelectOptions.addDialogHeaderTKey ?? ''"
        [addDropdownPlaceholderTKey]="column.chipSelectOptions.addDropdownPlaceholderTKey ?? ''"
        [disabled]="true"
        (onShow)="openChipSelect()"
      ></app-chip-select>
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.TEAM">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <p-dropdown
        [name]="column.name"
        [options]="dropdownOptions[column.name] | async"
        [(ngModel)]="value[column.name]"
        [showClear]="column.dropdownOptions?.clearable"
        dataKey="id"
        optionLabel="name"
        placeholder="{{'common.team' | translate}}"
        [filter]="true"
        filterBy="name"
        [disabled]="!column.editable"
        [required]="column.required ?? false"
        (onShow)="openDropdown()">
      </p-dropdown>
    </ng-template>
    <ng-template pTemplate="output">
      <span *ngIf="column.valuePipe">{{ column.valuePipe(value)?.name }}</span>
      <span *ngIf="!column.valuePipe">
        {{ value?.name }}
      </span>
    </ng-template>
  </p-cellEditor>
</ng-container>


<ng-container *ngIf="column.type === TableColumnType.ATTENDANCE">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <p-dropdown
        [name]="column.name"
        [options]="attendanceStates"
        [(ngModel)]="value[column.name]"
        [showClear]="column.dropdownOptions?.clearable"
        dataKey="value"
        optionLabel="label"
        optionValue="value"
        placeholder="{{'common.attendance' | translate}}"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
      </p-dropdown>
    </ng-template>
    <ng-template pTemplate="output">
      {{ 'attendance_state.' + value[column.name] | translate }}
    </ng-template>
  </p-cellEditor>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.RATING">
  <p-cellEditor>
    <ng-template pTemplate="input">
      <p-rating
        [name]="column.name"
        [(ngModel)]="value[column.name]"
        [stars]="column.ratingOptions?.stars ?? 5"
        [cancel]="column.ratingOptions?.cancel ?? false"
        [disabled]="!column.editable"
        [required]="column.required ?? false"
      />
    </ng-template>
    <ng-template pTemplate="output">
      <p-rating
        [name]="column.name"
        [(ngModel)]="value[column.name]"
        [stars]="column.ratingOptions?.stars ?? 5"
        [cancel]="column.ratingOptions?.cancel ?? false"
        [disabled]="true"
        [required]="column.required ?? false"
      />
    </ng-template>
  </p-cellEditor>
</ng-container>
